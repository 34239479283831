export const monacoThemes = [
  {
    id: 1,
    name: "Dark",
    label: "Dark",
    value: "vs-dark",
  },
  {
    id: 2,
    name: "Light",
    label: "Light",
    value: "vs",
  },
];