import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Avatar.css';
import { useDispatch } from 'react-redux';
import { logout } from '../../reducers/auth';
import classNames from 'classnames';
import MenuBarMenu from '../../components/menu-bar/menu-bar-menu.jsx';
import { MenuItem, MenuSection } from '../../components/menu/menu.jsx';

export default function Avatar() {
	const avatar = useSelector(state => state.scratchGui.auth.avatar);
	const name = useSelector(state => state.scratchGui.auth.name);
	const dispatch = useDispatch();
	const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);

	const showProfileMenu = () => setIsProfileMenuVisible(!isProfileMenuVisible);
	const hideProfileMenu = () => setIsProfileMenuVisible(false);

	return (
		<div className={`text-center cursor-pointer ml-2 ${styles.profile}`}>
			<div
				className={classNames(styles.menuBarItem, styles.hoverable, {
					[styles.active]: isProfileMenuVisible,
				})}
			>
				<div 
					className="btn m-1 bg-transparent hover:bg-transparent border-none"
					onClick={showProfileMenu}
				>
					<img src={avatar} alt='' />
				</div>
				<MenuBarMenu
					className={classNames(styles.menuBarMenu)}
					open={isProfileMenuVisible}
					place={'left'}
					onRequestClose={hideProfileMenu}
				>
					<MenuSection>
						<MenuItem>{name}</MenuItem>
						<MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
					</MenuSection>
				</MenuBarMenu>
			</div>
		</div>
	);
}
