import React, { useState, useRef, useEffect } from 'react';
import google from '../../../assets/google.svg';
import styles from '../Login/Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../../reducers/auth';
import ReactS3Uploader from 'react-s3-uploader';
import Image from '../../../assets/PPic.png';
import LoaderComponent from '../../loader/loader.jsx';
import axios from '../../../api/axios';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ROBO_LOGIN from '../../../assets/robo_login.png';

const s3BucketUrl = 'https://tinkerbrix.s3.ap-south-1.amazonaws.com';

const Signup = () => {
	const [username, setUsername] = useState('');
	const [schoolId, setSchoolId] = useState('');
	const [schoolList, setSchoolList] = useState(['']);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [userType, setUserType] = useState('student');
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(state => state.scratchGui.auth.isAuthenticated);
	const loading = useSelector(state => state.scratchGui.auth.loading);

	const [errMsg, setErrMsg] = useState('');
	const [passErr, setPassErr] = useState('');
	const [nameErr, setNameErr] = useState('');
	const [emailErr, setEmailErr] = useState('');
	const [avatarS3FileKey, setAvatarS3FileKey] = useState('');
	const [avatarS3PublicUrl, setAvatarS3PublicUrl] = useState('');
	const [uploadProgress, setUploadProgress] = useState(0);
	const [avatarUploaded, setAvatarUploaded] = useState(false);
	const uploader = useRef();
	const modalRef = useRef();

	const [success, setSuccess] = useState(false);
	const saveAvatar = data => {
		console.log(data);
		setAvatarS3FileKey(data.fileKey);
		setAvatarS3PublicUrl(data.publicUrl);
		setAvatarUploaded(true);
	};

	const getAllSchools = async () => {
		const resultSchools = await axios.get('https://app.tinkerbrix.cc/api/schoolUser/allschools');
		setSchoolList(resultSchools.data);
	};

	useEffect(() => {
		if (isAuthenticated) {
			modalRef.current.checked = false;
		}
		getAllSchools();
	}, [isAuthenticated]);

	const handleRegisterUser = async e => {
		e.preventDefault();
		const regUser = {
			name: name,
			userType: 'student',
			username: username,
			email: email,
			school: schoolId,
			password: password,
			avatar: `${s3BucketUrl}/${avatarS3FileKey}`,
		};

		try {
			dispatch(register(regUser));

			setSuccess(true);
		} catch (err) {
			console.log(err);
			// eslint-disable-next-line no-unused-expressions
			err?.response?.data?.errors?.forEach(error => {
				if (error?.param === 'password') {
					setPassErr(error?.msg);
				}
				if (error?.param === 'name') {
					setNameErr(error?.msg);
				}
				if (error?.param === 'email') {
					setEmailErr(error?.msg);
				}
			});
		}
	};

	const handleGoogleLogin = e => {
		window.open(`localhost:5001/api/auth/register/google/${userType}`, '_self');
	};

	return (
		<>
			<input ref={modalRef} type='checkbox' id='signup-modal' className='modal-toggle' />
			<div className='modal modal-top sm:modal-middle'>
				<div className={`modal-box relative w-11/12 !max-w-[50rem] bg-white py-12 ${styles.loginModal}`}>
					<label htmlFor='signup-modal' className={`absolute right-6 top-4 ${styles.closeBtn}`}>
						✕
					</label>
					<h3 className={`my-2 mx-auto text-xl font-normal text-black text-center mb-3 ${styles.loginTitle}`}>
						Welcome! Sign up to Robospecies!
					</h3>
					<div className='flex items-end'>
						<div className={`w-full md:w-1/2 px-5 ${styles.modalBody}`}>
							{/* <img
								style={{ margin: 'auto' }}
								src={avatarS3FileKey !== '' ? `${s3BucketUrl}/${avatarS3FileKey}` : Image}
								alt='Profile Picture'
							/>
							<div style={{ textAlign: 'center' }}>
								<ReactS3Uploader
									signingUrl='/s3/sign'
									signingUrlMethod='GET'
									accept='img/*'
									s3path='profile_pics/students/'
									scrubFilename={filename => `profile_pic`}
									onProgress={setUploadProgress}
									onFinish={saveAvatar}
									server={'https://app.tinkerbrix.cc'}
									autoUpload={true}
									onError={message => console.log(message)}
									uploadRequestHeaders={{
										'x-amz-acl': 'private',
									}}
									contentDisposition='auto'
									ref={uploader}
								/>
								<br />
								<p style={{ color: 'black' }}>
									{avatarUploaded
										? 'Uploaded'
										: uploadProgress === 0
										? 'Upload Profile Pic'
										: `${uploadProgress}%`}
								</p>
							</div> */}
							<form onSubmit={handleRegisterUser} className={`${styles.loginForm} mt-[25px]`}>
								<div className={`${styles.formInput}`}>
									<p className='mb-1'>
										<label htmlFor='name'>Name</label>
									</p>
									<input
										type='text'
										id='name'
										placeholder='Your name'
										name='name'
										style={{ color: 'black' }}
										className={`${nameErr ? 'border border-[#ff0033]' : ''}`}
										onChange={e => setName(e.target.value)}
									/>
								</div>
								{nameErr ? <p className='text-[#ff0033] text-xs'>{nameErr}</p> : ''}
								<div className={`${styles.formInput} mt-[13px]`}>
									<p className='mb-1'>
										<label htmlFor='email'>Email</label>
									</p>
									<input
										className={`${errMsg ? 'border border-[#ff0033]' : ''} ${
											emailErr ? 'border border-[#ff0033]' : ''
										}`}
										id='email'
										type='email'
										style={{ color: 'black' }}
										placeholder='Enter a email address'
										onChange={e => setEmail(e.target.value)}
									/>
								</div>
								{emailErr ? <p className='text-[#ff0033] text-xs'>{emailErr}</p> : ''}
								{/* <div className={`${styles.formInput} mt-[13px]`}>
									<p>
										<label htmlFor='username'>Username</label>
									</p>
									<input
										className={`${errMsg ? 'border border-[#ff0033]' : ''}`}
										type='text'
										style={{ color: 'black' }}
										id='username'
										placeholder='Enter a username'
										name='username'
										onChange={e => setUsername(e.target.value)}
									/>
								</div> */}
								<div className={`${styles.formInput} mt-[13px]`}>
									<p className='mb-1'>
										<label htmlFor='username'>Select School</label>
									</p>
									<Select
										labelId='school-select'
										id='school-select'
										value={schoolId}
										style={{
											borderRadius: '8px',
											fontSize: '0.95rem',
											fontFamily: 'Poppins, sans-serif'
										}}
										className={`w-full ${styles.schoolSelect}`}
										onChange={e => setSchoolId(e.target.value)}
										variant='outlined'
									>
										{schoolList.map(schoolData => (
											<MenuItem value={schoolData._id}>{schoolData.name}</MenuItem>
										))}
									</Select>
								</div>
								<div className={`${styles.formInput} mt-[13px]`}>
									<p className='mb-1'>
										<label htmlFor='password'>Password</label>
									</p>
									<input
										id='password'
										type='password'
										style={{ color: 'black' }}
										placeholder='Enter a password'
										className={`${passErr ? 'border border-[#ff0033]' : ''}`}
										onChange={e => setPassword(e.target.value)}
									/>
								</div>
								{passErr ? <p className='text-[#ff0033] text-xs'>{passErr}</p> : ''}

								{errMsg ? <p className='text-[#ff0033] pt-5'>{errMsg}</p> : ''}
								<p className='text-center mt-5'>
									<button disabled={loading} type='submit'>
										{loading ? 'Loading...' : 'Create account!'}
									</button>
								</p>
							</form>
							<div className='divider text-black text-base font-normal my-6'>Or With</div>
							{/* Social Login */}
							<div
								className={`flex justify-center items-center ${styles.socialLogin}`}
								onClick={handleGoogleLogin}
							>
								<p>
									<img className='w-[24px]' src={google} alt='' />
								</p>
								<p className={styles.googleLoginText}>Signup with Google</p>
							</div>
							<p className={styles.signupText}>
								Have an account already?
								<label htmlFor='login-modal' onClick={() => (modalRef.current.checked = false)}>
									{' '}
									Log in here
								</label>
							</p>
						</div>
						<div className={`w-full md:w-1/2 hidden md:flex items-end justify-center`}>
							<img src={ROBO_LOGIN} alt="" className='w-11/12 object-contain' />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Signup;
