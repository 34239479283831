import { applyMiddleware, compose, combineReducers } from 'redux';
import alertsReducer, { alertsInitialState } from './alerts';
import assetDragReducer, { assetDragInitialState } from './asset-drag';
import cardsReducer, { cardsInitialState } from './cards';
import colorPickerReducer, { colorPickerInitialState } from './color-picker';
import connectionModalReducer, { connectionModalInitialState } from './connection-modal';
import customProceduresReducer, { customProceduresInitialState } from './custom-procedures';
import blockDragReducer, { blockDragInitialState } from './block-drag';
import editorTabReducer, { editorTabInitialState } from './editor-tab';
import hoveredTargetReducer, { hoveredTargetInitialState } from './hovered-target';
import menuReducer, { menuInitialState } from './menus';
import micIndicatorReducer, { micIndicatorInitialState } from './mic-indicator';
import modalReducer, { modalsInitialState } from './modals';
import modeReducer, { modeInitialState } from './mode';
import monitorReducer, { monitorsInitialState } from './monitors';
import monitorLayoutReducer, { monitorLayoutInitialState } from './monitor-layout';
import projectChangedReducer, { projectChangedInitialState } from './project-changed';
import projectStateReducer, { projectStateInitialState } from './project-state';
import projectTitleReducer, { projectTitleInitialState } from './project-title';
import fontsLoadedReducer, { fontsLoadedInitialState } from './fonts-loaded';
import restoreDeletionReducer, { restoreDeletionInitialState } from './restore-deletion';
import stageSizeReducer, { stageSizeInitialState } from './stage-size';
import targetReducer, { targetsInitialState } from './targets';
import timeoutReducer, { timeoutInitialState } from './timeout';
import toolboxReducer, { toolboxInitialState } from './toolbox';
import vmReducer, { vmInitialState } from './vm';
import vmStatusReducer, { vmStatusInitialState } from './vm-status';
import workspaceMetricsReducer, { workspaceMetricsInitialState } from './workspace-metrics';
import throttle from 'redux-throttle';
import thunk from 'redux-thunk';
import programModeReducer, { programModeInitialState } from './program-mode';
import codeReducer, { codeInitialState } from './code';
import deviceReducer, { deviceInitialState } from './device';
import deviceDataReducer, { deviceDataInitialState } from './device-data';
import hardwareConsoleReducer, { hardwareConsoleInitialState } from './hardware-console';
import updateReducer, { updateInitialState } from './update';

import authReducer, { authInitialState } from './auth';
import projectReducer, { projectInitialState } from './project';

import decks from '../lib/libraries/decks/index.jsx';

const guiMiddleware = compose(
	applyMiddleware(...[throttle(300, { leading: true, trailing: true }), thunk])
);

const guiInitialState = {
	auth: authInitialState,
	alerts: alertsInitialState,
	assetDrag: assetDragInitialState,
	blockDrag: blockDragInitialState,
	cards: cardsInitialState,
	code: codeInitialState,
	colorPicker: colorPickerInitialState,
	connectionModal: connectionModalInitialState,
	customProcedures: customProceduresInitialState,
	device: deviceInitialState,
	deviceData: deviceDataInitialState,
	hardwareConsole: hardwareConsoleInitialState,
	editorTab: editorTabInitialState,
	mode: modeInitialState,
	hoveredTarget: hoveredTargetInitialState,
	stageSize: stageSizeInitialState,
	menus: menuInitialState,
	micIndicator: micIndicatorInitialState,
	modals: modalsInitialState,
	monitors: monitorsInitialState,
	monitorLayout: monitorLayoutInitialState,
	project: projectInitialState,
	projectChanged: projectChangedInitialState,
	projectState: projectStateInitialState,
	projectTitle: projectTitleInitialState,
	fontsLoaded: fontsLoadedInitialState,
	restoreDeletion: restoreDeletionInitialState,
	targets: targetsInitialState,
	timeout: timeoutInitialState,
	toolbox: toolboxInitialState,
	vm: vmInitialState,
	vmStatus: vmStatusInitialState,
	workspaceMetrics: workspaceMetricsInitialState,
	programMode: programModeInitialState,
	update: updateInitialState,
};

const initPlayer = function (currentState) {
	return Object.assign({}, currentState, {
		mode: {
			isFullScreen: currentState.mode.isFullScreen,
			isPlayerOnly: true,
			// When initializing in player mode, make sure to reset
			// hasEverEnteredEditorMode
			hasEverEnteredEditor: false,
		},
	});
};
const initFullScreen = function (currentState) {
	return Object.assign({}, currentState, {
		mode: {
			isFullScreen: true,
			isPlayerOnly: currentState.mode.isPlayerOnly,
			hasEverEnteredEditor: currentState.mode.hasEverEnteredEditor,
		},
	});
};

const initEmbedded = function (currentState) {
	return Object.assign({}, currentState, {
		mode: {
			showBranding: true,
			isFullScreen: true,
			isPlayerOnly: true,
			hasEverEnteredEditor: false,
		},
	});
};

const initTutorialCard = function (currentState, deckId) {
	return Object.assign({}, currentState, {
		cards: {
			visible: true,
			content: decks,
			activeDeckId: deckId,
			expanded: true,
			step: 0,
			x: 0,
			y: 0,
			dragging: false,
		},
	});
};

const initTelemetryModal = function (currentState) {
	return Object.assign({}, currentState, {
		modals: {
			telemetryModal: true, // this key must match `MODAL_TELEMETRY` in modals.js
		},
	});
};

const guiReducer = combineReducers({
	alerts: alertsReducer,
	assetDrag: assetDragReducer,
	blockDrag: blockDragReducer,
	cards: cardsReducer,
	code: codeReducer,
	auth: authReducer,
	project: projectReducer,
	colorPicker: colorPickerReducer,
	connectionModal: connectionModalReducer,
	customProcedures: customProceduresReducer,
	device: deviceReducer,
	deviceData: deviceDataReducer,
	editorTab: editorTabReducer,
	mode: modeReducer,
	hardwareConsole: hardwareConsoleReducer,
	hoveredTarget: hoveredTargetReducer,
	stageSize: stageSizeReducer,
	menus: menuReducer,
	micIndicator: micIndicatorReducer,
	modals: modalReducer,
	monitors: monitorReducer,
	monitorLayout: monitorLayoutReducer,
	projectChanged: projectChangedReducer,
	projectState: projectStateReducer,
	projectTitle: projectTitleReducer,
	fontsLoaded: fontsLoadedReducer,
	restoreDeletion: restoreDeletionReducer,
	targets: targetReducer,
	timeout: timeoutReducer,
	toolbox: toolboxReducer,
	vm: vmReducer,
	vmStatus: vmStatusReducer,
	workspaceMetrics: workspaceMetricsReducer,
	programMode: programModeReducer,
	update: updateReducer,
});

export {
	guiReducer as default,
	guiInitialState,
	guiMiddleware,
	initEmbedded,
	initFullScreen,
	initPlayer,
	initTelemetryModal,
	initTutorialCard,
};
