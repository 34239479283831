import React, { useState, useEffect, useRef } from 'react';
import ROBO_LOGIN from '../../../assets/robo_login.png';
import GOOGLE_ICON from '../../../assets/google.svg';
import styles from './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../reducers/auth';

const Login = () => {
	const [email, setEmail] = useState('');
	const modalRef = useRef();
	const [password, setPassword] = useState('');
	const [emailErr, setEmailErr] = useState('');
	const [passErr, setPassErr] = useState('');
	const [showing, setShowing] = useState(false);
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(state => state.scratchGui.auth.isAuthenticated);
	const loading = useSelector(state => state.scratchGui.auth.loading);

	useEffect(() => {
		if (isAuthenticated) {
			modalRef.current.checked = false;
		}
	}, [isAuthenticated]);

	const handleLoginUser = e => {
		e.preventDefault();
		dispatch(login({ email, password }));
	};

	return (
		<>
			<input ref={modalRef} type='checkbox' id='login-modal' className='modal-toggle' />
			<div className='modal modal-top sm:modal-middle'>
				<div className={`modal-box relative w-11/12 !max-w-[50rem] bg-white py-12 ${styles.loginModal}`}>
					<label htmlFor='login-modal' className={`absolute right-10 top-8 ${styles.closeBtn}`}>
						✕
					</label>
					<h3 className={`my-2 mx-auto text-xl font-normal text-black text-center mb-3 ${styles.loginTitle}`}>
						Hey there! Login to Robospecies!
					</h3>
					<div className='flex items-end'>
						<div className={`w-1/2 px-5 ${styles.modalBody}`}>
							{/* <div className={`flex justify-around ${styles.loginUser}`}>
								<p className={`text-center py-3 user-item ${styles.activeUser}`}>Student</p>
								<p className={`text-center py-3 ${styles.userItem}`}>Parent</p>
								<p className='text-center py-3'>Teacher</p>
							</div> */}
							<form onSubmit={handleLoginUser} className={`${styles.loginForm} mt-[25px]`}>
								<div className={styles.formInput}>
									<p className='mb-1'>
										<label htmlFor='email'>Email</label>
									</p>
									<input
										type='email'
										placeholder='Enter Your Email'
										className={`${emailErr ? 'border border-[#ff0033]' : ''}`}
										style={{ color: 'black' }}
										onChange={e => setEmail(e.target.value)}
									/>
								</div>
								{emailErr ? <p className='text-[#ff0033] text-xs'>{emailErr}</p> : ''}
								<div className={`${styles.formInput} mt-[13px]`}>
									<p className='mb-1'>
										<label htmlFor='password'>Password</label>
									</p>
									<input
										type='password'
										placeholder='Enter your password'
										className={`${passErr ? 'border border-[#ff0033]' : ''}`}
										style={{ color: 'black' }}
										onChange={e => setPassword(e.target.value)}
									/>
								</div>
								{passErr ? <p className='text-[#ff0033] text-xs'>{passErr}</p> : ''}
								<div className="form-control flex flex-row justify-between items-center my-2">
									<label className="label cursor-pointer justify-start gap-2">
										<input type="checkbox" checked="checked" className="checkbox checkbox-sm" />
										<span className="label-text font-normal">Remember me</span>
									</label>
									<p className='text-sm text-[#E86969] cursor-pointer font-normal'>Forgot Password?</p>
								</div>
								<p className='text-center'>
									<button disabled={loading} type='submit'>
										{loading ? 'Logging In...' : 'Log In'}
									</button>
								</p>
							</form>
							<div className='divider text-black text-base font-normal my-6'>Or With</div>
							{/* Social Login */}
							<div className={`flex justify-center items-center ${styles.socialLogin}`}>
								<p>
									<img className='w-[24px]' src={GOOGLE_ICON} alt='' />
								</p>
								<p className={styles.googleLoginText}>Login with Google</p>
							</div>
							<p className={styles.signupText}>
								Don’t have an account?
								<label htmlFor='signup-modal' className='ml-2' onClick={() => (modalRef.current.checked = false)}>
									Sign up
								</label>
							</p>
						</div>
						<div className={`w-1/2 flex items-end justify-center`}>
							<img src={ROBO_LOGIN} alt="" className='w-9/12 object-contain' />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
