import React, { useEffect, useState } from "react";
import CodeEditor from "../../containers/code-editor.jsx";

const CodeEditorWindow = ({ onChange, language, code, theme }) => {
  const [value, setValue] = useState(code || "");

  const handleEditorChange = (value) => {
    setValue(value);
    onChange("code", value);
  };

  function onCodeEditorWillMount (monaco) {
    monaco.editor.defineTheme('readOnlyTheme', {
        base: 'vs',
        inherit: true,
        rules: [{background: 'F9F9F9'}],
        colors: {
            'editor.background': '#F9F9F9'
        }
    });
  }

  function onCodeEditorDidMount (editor) {
      // Close the alert message from editor
      const messageContribution = editor.getContribution(
          'editor.contrib.messageController'
      );
      if (messageContribution) {
          messageContribution.dispose();
      }

      editor.onDidAttemptReadOnlyEdit(() => {
          this.props.onCodeEditorIsLocked();
      });
  }
  const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight});

  useEffect(() => {
    const handleResize = () => setDimensions({width: window.innerWidth, height: window.innerHeight});
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [language]);

  const EDITOR_WIDTH = dimensions.width < 950 ? dimensions.width - 60 : ((dimensions.width - 60) * 68) / 100
  const EDITOR_HEIGHT = dimensions.height < 800 ? 800 : dimensions.height - 200

  useEffect(() => {
    setValue(code)
  }, [code])
  

  return (
    <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
      <CodeEditor
        height={EDITOR_HEIGHT}
        width={EDITOR_WIDTH}
        language={language || "python"}
        value={value}
        theme={theme}
        defaultValue="// some comment"
        onChange={handleEditorChange}
        editorWillMount={onCodeEditorWillMount}
        editorDidMount={onCodeEditorDidMount}
        options={{ readOnly: false }}
      />
    </div>
  );
};
export default CodeEditorWindow;
