import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Container } from '@material-ui/core';
import { Avatar, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const Leaderboard = () => {
	const [leaderData, setLeaderData] = useState([]);
	const dispatch = useDispatch();
	const getLeaders = async () => {
		const leaders = await axios.get('https://app.tinkerbrix.cc/api/admin/leaderboard');
		setLeaderData(leaders.data);
	};
	useEffect(() => {
		getLeaders();
	}, []);
	console.log(leaderData);
	return (
		<div>
			<Container>
				<Box height={40} />
				<Typography variant='h2'>Leaderboard</Typography>
				<Box height={30} />
				<TableContainer component={Paper}>
					<Table style={{ minWidth: '600' }} aria-label='leaderboard table'>
						<TableHead>
							<TableRow>
								<TableCell>Rank</TableCell>
								<TableCell align='left'>Student</TableCell>
								<TableCell align='right'>Number Of Projects</TableCell>
								<TableCell align='right'>School Name</TableCell>
								<TableCell align='right'>Best Project</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{leaderData.map((row, index) => (
								<TableRow key={index}>
									<TableCell component='th' scope='row'>
										{index + 1}
									</TableCell>
									<TableCell align='left' style={{ display: 'flex', alignItems: 'center' }}>
										<Avatar src={row.avatar} />
										<Box width={'1rem'} />
										{row.name}
									</TableCell>
									<TableCell align='right'>{row.projects.length}</TableCell>
									<TableCell align='right'>
										{row.school.length > 0 ? row.school[0].name : '-'}
									</TableCell>
									<TableCell align='right'>
										<a
											href={`https://app.tinkerbrix.cc/scratch#${
												row.projects[0].projectURL.split('/')[2]
											}`}
										>
											{row.projects[0].projectName}
										</a>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</div>
	);
};

export default Leaderboard;
