import React from "react";
import { classnames } from "../../util/general";

const CustomInput = ({ customInput, setCustomInput }) => {
  return (
    <div className="w-full h-full relative">
      {" "}
      <textarea
        rows="5"
        value={customInput}
        onChange={(e) => setCustomInput(e.target.value)}
        placeholder={`Custom input`}
        className={classnames(
          "focus:outline-none w-full border-2 border-[#3b5998] z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(59,89,152)] px-4 py-2 hover:shadow transition duration-200 bg-white mt-2"
        )}
      ></textarea>
      <span className="font-light text-gray-400 text-xs">Press <i>Ctrl + Enter</i> {" "} to Compile</span>
    </div>
  );
};

export default CustomInput;
