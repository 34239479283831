import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import Message from './components/dashboard/Message/Message';
import Dashboard from './containers/dashboard.jsx';
import Landing from './containers/landing.jsx';
import Learn from './containers/learn.jsx';
import Python from './containers/python.jsx';
import Tutorials from './containers/tutorials';
import './playground/index.css';
import Circuits from './containers/circuits.jsx';
import ExtendedReality from './containers/extended-reality.jsx';
import StemPhet from './containers/stem-phet.jsx';
import Leaderboard from './containers/leaderboard.jsx';
import Cpp from './containers/cpp.jsx';

const App = ({ guiComponent }) => {
	//  todo
	//  useEffect(() => {
	// 	const handleInvalidToken = e => {
	// 		if (e.key === TOKEN_KEY && e.oldValue && !e.newValue) {
	// 			useDispatch(logout());
	// 		}
	// 	};
	// 	window.addEventListener('storage', handleInvalidToken);
	// 	return function cleanup() {
	// 		window.removeEventListener('storage', handleInvalidToken);
	// 	};
	// }, []);
	return (
		<div className='bg-[white]'>
			<ParallaxProvider>
				<Router>
					<Routes>
						<Route path='/' element={<Learn />} />
						<Route path={'/scratch'} element={guiComponent} />
						<Route path='/learn' element={<Learn />} />
						<Route path='/tutorials' element={<Tutorials />} />
						<Route path='/dashboard' element={<Dashboard />} />
						<Route path='/leaderboard' element={<Leaderboard />} />
						<Route path='/message' element={<Message />} />
						<Route path='/python' element={<Python />} />
						<Route path='/cpp' element={<Cpp />} />
						<Route path='/circuits' element={<Circuits />} />
						<Route path='/xr' element={<ExtendedReality />} />
						<Route path='/stem' element={<StemPhet />} />
					</Routes>
				</Router>
			</ParallaxProvider>
		</div>
	);
};

export default App;
